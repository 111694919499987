import { OrbitControls, PerspectiveCamera, useTexture } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { GiHamburgerMenu, GiJellyfish } from "react-icons/gi";
import "./App.css";

function Panel() {
  const textureProps = useTexture({map: 'jelly-bit-box.jpg'})

  return (
    <mesh position={[2,0,0]}>
      <planeGeometry args={[2,2]} />
      <meshStandardMaterial {...textureProps} side={2} />
    </mesh>
  )
}

function App() {
  function toggleMenu() {
    const menu = document.querySelector(".mobile-menu");
    menu?.classList.toggle("hidden");
  }

  return (
    <>
      <nav className="flex items-center justify-between flex-wrap bg-gray-800 p-6">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <div className="text-blue-600">
            <GiJellyfish size={64} />
          </div>
          <span className="font-semibold text-xl tracking-tight">
            Jelly Bit Studio
          </span>
        </div>
        <div className="block lg:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded text-blue-200 border-blue-400 hover:text-white hover:border-white"
            onClick={toggleMenu}
          >
            <GiHamburgerMenu />
          </button>
        </div>
        <div className="hidden w-full block flex-grow lg:flex lg:items-center lg:w-auto">
          <div className="text-sm lg:flex-grow">
            <a
              href="index.html"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white mr-4"
            >
              Home
            </a>
            <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white mr-4"
            >
              About
            </a>
            <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white mr-4"
            >
              Consulting Services
            </a>
            <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white mr-4"
            >
              Games
            </a>
            <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white mr-4"
            >
              Apps
            </a>
            <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white mr-4"
            >
              Developer Blog
            </a>
            <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white mr-4"
            >
              Jelly Lore
            </a>
            <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-blue-200 hover:text-white"
            >
              Gallery
            </a>
          </div>
        </div>
        <div className="hidden mobile-menu">
          <ul className="">
            <li className="active">
              <a
                href="index.html"
                className="block text-sm px-2 py-4 hover:bg-blue-400 text-blue-200 transition duration-300"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#responsive-header"
                className="block text-sm px-2 py-4 hover:bg-blue-400 text-blue-200 transition duration-300"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#responsive-header"
                className="block text-sm px-2 py-4 hover:bg-blue-400 text-blue-200 transition duration-300"
              >
                Consulting Services
              </a>
            </li>
            <li>
              <a
                href="#responsive-header"
                className="block text-sm px-2 py-4 hover:bg-blue-400 text-blue-200 transition duration-300"
              >
                Games
              </a>
            </li>
            <li>
              <a
                href="#responsive-header"
                className="block text-sm px-2 py-4 hover:bg-blue-400 text-blue-200 transition duration-300"
              >
                Apps
              </a>
            </li>
            <li>
              <a
                href="#responsive-header"
                className="block text-sm px-2 py-4 hover:bg-blue-400 text-blue-200 transition duration-300"
              >
                Developer Blog
              </a>
            </li>
            <li>
              <a
                href="#responsive-header"
                className="block text-sm px-2 py-4 hover:bg-blue-400 text-blue-200 transition duration-300"
              >
                Jelly Lore
              </a>
            </li>
            <li>
              <a
                href="#responsive-header"
                className="block text-sm px-2 py-4 hover:bg-blue-400 text-blue-200 transition duration-300"
              >
                Gallery
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <h1 className="px-6 text-4xl font-extrabold tracking-tight md:text-5xl xl:text-6xl dark:text-white">
        Welcome to Jelly Bit Studio!
      </h1>

      <div style={{ width: "100vw", height: "50vh" }}>
        <Canvas>
          <OrbitControls />
          <ambientLight intensity={0.1} />
          <PerspectiveCamera fov={35} position={[0,0,10]} makeDefault>
            <directionalLight color="yellow" position={[0, 0, 5]} />
          </PerspectiveCamera>
          <mesh>
            <boxGeometry />
            <meshStandardMaterial />
          </mesh>
          <Suspense fallback={null}>
            <Panel />
          </Suspense>
        </Canvas>
      </div>

      <footer className="fixed bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
        <ul className="flex flex-wrap items-center mt-3 text-sm text-blue-200 dark:text-blue-200 sm:mt-0">
          <li>
            <a
              href="https://jawns.club/@joebarbere"
              className="mr-4 hover:underline md:mr-6 "
            >
              @joebarbere@jawns.club
            </a>
          </li>
        </ul>
        <span className="text-sm text-blue-200 sm:text-center dark:text-blue-200">
          © {new Date().getFullYear()} Jelly Bit Studio, LLC
        </span>
      </footer>
    </>
  );
}

export default App;
